/** @jsx jsx */

import { Link } from 'gatsby'
import { jsx } from 'theme-ui'

import Halo from '../components/halo'
import { faces, formatFace } from '../utilities'

const Grid = () => {
  return (
    <div sx={{ fontFamily: `body` }}>
      <Halo title="Grid" />
      <style>{`body { margin: 0 }`}</style>

      <main sx={{ bg: `#000` }}>
        <ul
          sx={{
            my: 0,
            pl: 0,
            listStyleType: `none`,
            fontFamily: `monospace`,
            justifyContent: `space-between`,
            gap: `0.25rem`,
            display: `grid`,
            gridTemplateColumns: `repeat(auto-fill, minmax(200px, 1fr))`,
          }}
        >
          {faces.slice(0, 1000).map(({ code, number }) => {
            return (
              <Link
                to={`/characters/${number}`}
                sx={{
                  textDecoration: `none`,
                  color: `inherit`,
                  aspectRatio: `1`,
                  bg: `hsl(${(Math.random() * 255) / 3 + 130}, 100%, 80%)`,
                  display: `grid`,
                  alignItems: `center`,
                  justifyContent: `center`,
                }}
              >
                <pre
                  sx={{
                    my: 0,
                    fontSize: `1.5em`,
                    lineHeight: `1.2`,
                    fontFamily: `monospace`,
                    position: `relative`,
                    color: `black`,
                  }}
                >
                  {formatFace(code)}
                </pre>
              </Link>
            )
          })}
        </ul>
      </main>
    </div>
  )
}

export default Grid
